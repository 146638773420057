export const VisualizationTableAnswerLegend = ({ data, multi }) => {
  const scales = !multi ? [40, 80, 120, 160] : [20, 40, 60, 80]

  return (
    <div className="row row--body">
      <div className="col col-head" />
      {data.map((d) => (
        <div className="col col-data" key={d}>
          <div className="lines lines--short">
            { scales.map (d => (
              <span>
                <p>{d}</p>
              </span>
            ))}
          </div>
          <ul className="list-unstyled list-numbers">
            <li>0</li>
          </ul>
          <p className="col-label">Num. of countries</p>
        </div>
      ))}
    </div>
  );
};
