import banner from '@assets/img/banner_EN.png';
import iconInfo from '@assets/img/icon-info.png';

export function Header() {
  return (
    <header className="page-header">
      <div className="page-header__banner">
      <div className="page-header__notification">
        <span className="page-header__icon">
          <img src={iconInfo} alt="" width={24} />
        </span>
        <span className="page-header__text text-bold">
          For 2024 data, please check our new website (in development)
        </span>
        {/* <a className="page-header__view-button" target="_blank" href={`https://new.amrcountryprogress.org?ref=${window.location}`}>VIEW</a> */}
        <a className="page-header__view-button" href="https://new.amrcountryprogress.org">VIEW</a>
    </div>
      </div>

      <div className="page-header__row">
        <div className="page-header__title">
          <h1>
          Global Database for Tracking Antimicrobial Resistance (AMR)
          <br/> Country Self- Assessment Survey (TrACSS)
          </h1>
        </div>
        {/* OLD LOGOS => c51adcb */}
        <div className="page-header__branding">
          <img
              className="logo-banner"
              src={banner}
              alt="Global Database for Tracking Antimicrobial Resistance (AMR) Country Self- Assessment Survey (TrACSS)"
            />
        </div>
      </div>
    </header>
  );
}
